import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Nav from '../components/nav';
import MobileNav from '../components/mobileNav';
import Footer from '../components/footer';
import './design.scss';

const DesignTemplate = (props) => {
  return (
    <Layout>
      <MobileNav />
      <Nav />
      <div className="design">
        <img className="__image" src={props.data.contentfulDesign.featuredImage.fluid.src} alt="design" />
        <div className="__copy">
          <h1 className="__title">{props.data.contentfulDesign.title}</h1>
          <div dangerouslySetInnerHTML={
            {__html: `${props.data.contentfulDesign.description.childMarkdownRemark.html}`}
          } />
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default DesignTemplate;


export const query = graphql`
 query DesignTemplate($id: String!) {
   contentfulDesign(id: {eq: $id}) {
     title
     id
     slug
     description {
       childMarkdownRemark {
         html
       }
     }
     featuredImage {
       fluid(maxWidth: 1200, quality: 100) {
         ...GatsbyContentfulFluid
         src
       }
     }
   }
 }
`
